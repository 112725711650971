/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  overflow-y: scroll !important;
}

body {
  //overflow-y: scroll !important;
  padding-right: 0 !important;
  display: block;
  background-color: $navbar-bg;

  &.modal-open {
    overflow: unset!important;
    .modal {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  // hack fix for ios pwa statusbar colour
  &:before {
    content: '';
    position:fixed;
    width: 100vw;
    height: 100px;
    top: -100px;
    left: 0;
    background-color: $navbar-bg;
    z-index: 10000000000;
  }
  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $body-bg;
    z-index: -1;
  }
}

.content-container {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border: 1px soild $border-color!important;
  border-radius: $border-radius;
  background-color: $content-bg;
  padding: 1em;
  margin-bottom: 3em;
  clear: both;
  @include media-breakpoint-down(md) {
    border-radius: 0;
    margin-left: -8px;
    margin-right: -8px;
    padding: 0.5rem!important;
  }
  @include media-breakpoint-down(sm) {
    padding: 0.6em;
  }
}


.app-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: visible;
  position: relative;

  background-color: $body-bg;

  //min-height: 100vh;

  &.top-bar {
    min-height: calc(100vh - var(--navbar-height-total));
    padding-top: var(--navbar-height-total);

    &.offline {
      min-height: calc(100vh - (var(--navbar-height-total) + 20px));
      padding-top: calc(var(--navbar-height-total) + 20px);
      &::ng-deep {
        .requests-wrapper, .requests {
          height: calc(100vh - (var(--navbar-height-total) + 20px));
        }
      }
    }

  }

  main {
    flex: 1;
    min-width: 0;
    position: relative;

    > .container-fluid {
      overflow-x: clip;

      > * {
        display: block;
        width: 100%;
        margin: 0 auto;
        max-width: 1300px;
      }
    }

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}
