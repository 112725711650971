$primary: #db5700;
$secondary: #262626;

$gray: #838282;

$success: #63b160;
$danger: #fa6557;
$warning: #efa755;

/* these are non- bootstrap variables. */
$content-bg: #FFF;
$list-bg: #FFF;
$navbar-bg: #FFF;

$sidebar-bg: #fff;
$sidebar-highlight: $secondary;
$sidebar-selected: $primary;
$sidebar-selected-bg: #f5f5f5;
$sidebar-menu-btn: #000;
/* /these are non- bootstrap variables. */

$body-bg: #eaeaea;
$body-color: #191d24;
$headings-color: #1f232b;
$modal-content-bg: $content-bg;

$card-cap-bg: darken($content-bg, 15);
$card-bg: darken($content-bg, 4);

$form-label-color: #262626;
$form-label-font-weight: 200;
$input-bg: #f9f9f9;
$input-border-color: #bebebe;
$input-border-width: 1px;
$input-focus-bg: #FFF;
$input-placeholder-color: #999999;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: darken($primary, 10%);
$dropdown-link-hover-color: #fff;

$border-radius:    13px;
//$border-radius-lg: 23px;
//$border-radius-xl: 33px;

$input-padding-x: 0.5rem;
$input-padding-y: 0.3rem;

$input-btn-padding-x: 0.7rem;
$input-btn-padding-y: 0.3rem;

$input-font-size: 0.95rem;
$input-btn-font-size: 0.95rem;

$form-check-input-width: 1.3em;

$font-family-base: 'Assistant';
$font-family-sans-serif: 'Assistant';
$headings-font-family: 'Assistant';

$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 2px;

$enable-responsive-font-sizes: true;

$table-hover-bg: #ececec;
